import { createContext, useState, useMemo, ReactNode } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";

interface ThemeContextType {
  currentTheme: "light" | "dark";
  setTheme: (themeName: "light" | "dark") => void;
}

interface CustomThemeProviderProps {
  children: ReactNode;
}

const CustomThemeContext = createContext<ThemeContextType>({
  currentTheme: "light",
  setTheme: () => {}, // noop function
});

export const CustomThemeProvider = ({ children }: CustomThemeProviderProps) => {
  const [themeName, setThemeName] = useState<"light" | "dark">("light");

  const theme = useMemo(() => {
    return createTheme({
      palette: {
        mode: themeName,
      },
      // ... other theme settings
    });
  }, [themeName]);

  const contextValue = {
    currentTheme: themeName,
    setTheme: setThemeName,
  };

  return (
    <CustomThemeContext.Provider value={contextValue}>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </CustomThemeContext.Provider>
  );
};

export default CustomThemeContext;
