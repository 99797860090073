import React from "react";
import Grid from "@mui/material/Grid";

import landingImage from "../assets/landing-trs.png";

const LandingPage: React.FC = () => {
  //const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  //const theme = useTheme();
  //const [session, setSession] = useState<Session | null>(null);

  // const openModal = () => {
  //   setIsModalOpen(true);
  // };

  // const closeModal = () => {
  //   setIsModalOpen(false);
  // };

  return (
    <div style={{ height: "100vh" }}>
      <Grid container spacing={2} style={{ height: "100%" }}>
        <Grid
          item
          xs={12}
          md={8}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "flex-start",
            paddingTop: "5%",
          }}
        >
          <img
            src={landingImage}
            alt="Description of Image"
            style={{
              maxWidth: "80%",
              height: "auto",
            }}
          />
        </Grid>
        <Grid
          item
          xs={12}
          md={4}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
            padding: "1% 5% 0% 0%",
            maxHeight: "50%",
          }}
        >
          <div
            style={{
              textAlign: "center",
              fontSize: "24px",
              color: "#333", // choose a color that fits your design
              fontFamily: "'Arial', sans-serif", // choose a font that fits your branding
              lineHeight: "1.5", // adjust line height for better readability
              margin: "20px 0", // add some margin for spacing from other elements
              letterSpacing: "0.5px", // adjust letter spacing
              textShadow: "1px 1px 2px rgba(0,0,0,0.1)", // subtle text shadow for depth
              transition: "all 0.3s ease-in-out", // smooth transition for hover effe
            }}
          >
            Start practicing with AI
            <br /> to improve your reading
            <br /> and writing skills
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default LandingPage;
