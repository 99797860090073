import React, { useContext, useState } from "react";
import { AppBar, Toolbar, Button, Box } from "@mui/material";
import CustomThemeContext from "../themes/ThemeContext";
//import { Auth } from "@supabase/auth-ui-react";
//import { ThemeSupa } from "@supabase/auth-ui-shared";
import { SupabaseClient, Session } from "@supabase/supabase-js";
import AuthModal from "./AuthModal";

interface MyAppBarProps {
  session: Session | null;
  setSession: React.Dispatch<React.SetStateAction<Session | null>>;
  supabase: SupabaseClient;
}

const MyAppBar: React.FC<MyAppBarProps> = ({
  supabase,
  session,
  setSession,
}) => {
  const { setTheme } = useContext(CustomThemeContext);

  const [showLoginModal, setShowLoginModal] = useState(false);

  const handleLogin = () => {
    setShowLoginModal(true);
  };

  const handleLogout = async () => {
    const { error } = await supabase.auth.signOut();
    setSession(null);
    if (error) console.log("Error logging out:", error.message);
  };

  return (
    <AppBar position="static" color="primary" elevation={0}>
      <Toolbar>
        <Button color="inherit">Home</Button>
        <Box flexGrow={1} />
        {session ? (
          <Button color="inherit" onClick={handleLogout}>
            Logout
          </Button>
        ) : (
          <Button color="inherit" onClick={handleLogin}>
            Login
          </Button>
        )}
        <Button color="inherit" onClick={() => setTheme("light")}>
          About
        </Button>
      </Toolbar>
      <AuthModal
        session={session}
        supabaseClient={supabase}
        showModal={showLoginModal}
        onClose={() => setShowLoginModal(false)}
      />
    </AppBar>
  );
};

export default MyAppBar;
