import { PieChart, Pie, Cell, Label } from "recharts";

type GaugeChartProps = {
  value: number;
  total: number;
};

const GaugeChart: React.FC<GaugeChartProps> = ({ value, total }) => {
  const data = [
    { name: "Value", value: value },
    { name: "Rest", value: total - value },
  ];

  const COLORS = ["#0088FE", "#00C49F"];

  return (
    <PieChart width={200} height={200}>
      <Pie
        data={data}
        cx={100}
        cy={100}
        startAngle={210}
        endAngle={-20}
        innerRadius={60}
        outerRadius={80}
        fill="#8884d8"
        paddingAngle={5}
        dataKey="value"
      >
        {data.map((entry, index) => (
          <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
        ))}
        <Label
          value={`${value}%`}
          position="center"
          style={{ fill: "black", fontSize: 20 }}
        />
      </Pie>
    </PieChart>
  );
};

export default GaugeChart;
