import { useState, useEffect } from "react";
import { Grid, Container, Box, Button, useTheme } from "@mui/material";
import axios from "axios";
import "../App.css";
import GaugeChart from "../components/GaugeChart";
import TaskInput from "../components/TaskInput";
import UserInput from "../components/UserInput";
import GradeOutput from "../components/GradeOutput";
import EvaluationOutput from "../components/EvaluationOutput";
import AnalyticsPanel from "../components/AnalyticsPanel";
import HintDialog from "../components/HintDialog";

import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import InfoIcon from "@mui/icons-material/Info";

interface DiscussionEntry {
  role: string;
  content: string;
}

// If your API returns an object with a discussions property that is an array of DiscussionEntry
interface DiscussionsResponse {
  discussions: DiscussionEntry[];
}

const MainService: React.FC = () => {
  const [input, setInput] = useState<string>("");
  const [excerciseContent, setExcerciseContent] = useState<string>("");
  const [evaluationContent, setEvaluationContent] = useState<string>("");
  const [grade, setGrade] = useState<string>("");
  const [analyticsData, setAnalyticsData] = useState<string>("");
  const [complexityValue, setComplexityValue] = useState<number>(0);
  const [diversityValue, setDiversityValue] = useState<number>(0);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [gptContent, setGptContent] = useState<DiscussionEntry[]>([]);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);

  const theme = useTheme();

  const LOCALHOST = "http://localhost:8000";

  // save the user input
  const handleInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setInput(e.target.value);
  };

  // generate a new task
  const startPractice = async () => {
    setIsSubmitDisabled(false);
    setGptContent([]);
    setExcerciseContent("");
    setInput("");
    setEvaluationContent("");
    setGrade("");
    setAnalyticsData("");
    setComplexityValue(0);
    setDiversityValue(0);
    try {
      const res = await axios.post(`/start-excercise`, {
        user_input: input,
      });
      const task = JSON.parse(res.data.response);
      setGptContent(res.data.discussions.discussions);
      //console.log(task);

      setExcerciseContent(
        "Tilanne: " + task["Tilanne"] + "\n\nTehtävä: " + task["Tehtävä"]
      );
    } catch (error) {
      console.error("Error fetching response:", error);
    }
  };

  // submit the user response
  const handleSubmitUserInput = async () => {
    try {
      // First we analyze the text - statistics and complexity
      setIsSubmitDisabled(true);

      const response = await fetch(`/analyze`, {
        method: "POST", // Set the request method to POST
        headers: {
          // Include headers indicating the type of content being sent (JSON)
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          data: input, // Convert the JavaScript object to a JSON string
        }),
      });

      const data = await response.json();
      //console.log(data);
      setAnalyticsData(data.analytics);
      setComplexityValue(data.complexity);
      setDiversityValue(data.diversity);

      // then it's evaluated by chatGPT
      const res = await axios.post(`/evaluate`, {
        user_input: input,
        gptContent: gptContent,
      });
      const evaluation_response = JSON.parse(res.data.response);
      setEvaluationContent("Palaute: " + evaluation_response["Palaute"]);
      setGrade(evaluation_response["Arvosana"] + " / 10");
      //console.log(res.data.response);
    } catch (error) {
      console.error("Error fetching response:", error);
    }
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    //nothing to do at this point
  }, []);

  return (
    <Container maxWidth={false}>
      {/* Using Box here to manage background color dynamically from theme   */}
      <Box
        sx={(theme) => ({
          height: "100vh",
          background: `linear-gradient(to bottom, ${theme.palette.primary.main}, #FFFFFF)`,
        })}
      >
        <Grid container>
          {/* Splitting into 2 vertical columns 75% and 25% */}
          <Grid
            item
            xs={12}
            md={8}
            style={{ overflow: "hidden", borderRight: "1px solid #000" }}
          >
            {/*<div className="App">*/}
            {/*First row in the big column*/}
            <Grid container direction="column">
              <Grid
                item
                xs={12}
                style={{
                  padding: "1% 5%",
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                {/*** Get a task BUTTON ***/}
                <Button
                  variant="contained"
                  color="secondary"
                  style={{
                    height: "40px",
                    width: "160px",
                    borderRadius: "20px",
                  }}
                  onClick={startPractice}
                >
                  Get a new task
                </Button>
              </Grid>

              {/* Next row - TASK output field */}
              <Grid
                item
                xs={12}
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  padding: "1% 5% 1% 10%",
                  width: "100%",
                }}
              >
                {/* Task output field component */}
                <TaskInput excerciseContent={excerciseContent} theme={theme} />
              </Grid>

              {/* Next row - USER INPUT output field */}
              <Grid
                item
                xs={12}
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  padding: "1% 5% 1% 10%",
                  width: "100%",
                }}
              >
                {/* Task output field component */}
                <UserInput
                  handleInputChange={handleInputChange}
                  userContent={input}
                  theme={theme}
                />
              </Grid>

              {/* Next row - with submit reply BUTTON */}
              <Grid
                item
                xs={12}
                style={{
                  padding: "1% 5%",
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                {/*** Submit reply BUTTON ***/}
                <Button
                  variant="contained"
                  color="secondary"
                  disabled={isSubmitDisabled}
                  style={{
                    height: "40px",
                    width: "160px",
                    borderRadius: "20px",
                  }}
                  onClick={handleSubmitUserInput}
                >
                  Submit reply
                </Button>
              </Grid>

              {/* Next row - FEEDBACK output field */}
              <Grid
                item
                xs={12}
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  padding: "1% 5% 1% 10%",
                  width: "100%",
                  boxSizing: "border-box",
                }}
              >
                <Grid container spacing={0}>
                  {/* Splitting into 2 vertical columns 75% and 25% */}
                  <Grid item xs={12} md={3} style={{}}>
                    {/*** Grade output TEXTFIELD ***/}
                    <GradeOutput grade={grade} theme={theme} />
                    {/* Information Icon with Tooltip */}
                    <Tooltip
                      title="Please note that the grade value provided here is designed for reference and relative evaluation purposes only. It is NOT an absolute indicator of accuracy and quality. Our grading system is based on a set of criteria that aim to offer constructive feedback and a means for comparison rather than a definitive judgment of content. The grades are calculated through an automated process that considers various factors, which may not capture the full context or nuances of each response. As such, they should be used as a guideline to gauge performance and identify areas for improvement, rather than an ultimate measure of success or expertise." // The information you want to show
                      placement="right" // Tooltip placement can be 'left', 'right', 'bottom', 'top'
                      componentsProps={{
                        tooltip: {
                          sx: {
                            bgcolor: "#123456", // Custom background color
                            "& .MuiTooltip-arrow": {
                              color: "primary.main", // Arrow color to match the tooltip background
                            },
                            fontSize: "0.975rem", // Adjust font size here
                            maxWidth: "40%",
                          },
                        },
                      }}
                    >
                      <IconButton>
                        <InfoIcon />
                      </IconButton>
                    </Tooltip>
                  </Grid>

                  <Grid item xs={12} md={9}>
                    {/*** Evaluation output TEXTFIELD ***/}
                    <EvaluationOutput
                      evaluationContent={evaluationContent}
                      theme={theme}
                    />
                  </Grid>
                </Grid>
              </Grid>

              {/* Next row - hint BUTTON */}
              <Grid
                item
                xs={12}
                style={{
                  padding: "1% 5%",
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                {/*** Get a hint BUTTON ***/}
                <Button
                  variant="contained"
                  color="secondary"
                  style={{
                    height: "40px",
                    width: "160px",
                    borderRadius: "20px",
                  }}
                  onClick={openModal}
                >
                  Word hint
                </Button>
              </Grid>
            </Grid>
          </Grid>

          {/* Second column - analytics */}

          <Grid item xs={12} md={4} style={{ overflow: "hidden" }}>
            <Grid container direction="column">
              {/* <Grid container alignItems="top" spacing={1} sx={{}}> */}
              <Grid
                item
                xs={12}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  padding: "0",
                }}
                sx={{ marginRight: "0%", marginLeft: "0%" }}
              >
                {/*** Analytics panel output field ***/}
                <AnalyticsPanel analyticsData={analyticsData} theme={theme} />
              </Grid>
              <div className="centered">Usage of rare words</div>
              <Grid
                item
                xs={12}
                sm={1.5}
                sx={{ marginRight: "3%" }}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  padding: "0",
                }}
              >
                <GaugeChart value={complexityValue} total={100} />
              </Grid>
              <div className="centered">Words diversity</div>
              <Grid
                item
                xs={3}
                sm={1.5}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  padding: "0",
                }}
              >
                <GaugeChart value={diversityValue} total={100} />
              </Grid>
            </Grid>
          </Grid>
          {/*</div>*/}
        </Grid>
      </Box>
      <HintDialog isOpen={isModalOpen} closeModal={closeModal} theme={theme} />
    </Container>
  );
};

export default MainService;
