import TextField from "@mui/material/TextField";
import { Theme } from "@mui/material";

type TaskInputProps = {
  excerciseContent: string;
  theme: Theme;
};

const TaskInput: React.FC<TaskInputProps> = ({ excerciseContent, theme }) => {
  return (
    <TextField
      multiline
      variant="standard"
      style={{
        backgroundColor: theme.palette.background.paper,
        borderRadius: 30,
        padding: "10px 15px 10px 15px",
        width: "100%",
        minHeight: "15vh",
      }}
      value={excerciseContent}
      InputProps={{
        readOnly: true,
        style: {
          fontSize: "14px",
          paddingTop: "0px",
          paddingBottom: "0px",
          alignItems: "flex-start",
        },
        disableUnderline: true,
      }}
    />
  );
};

export default TaskInput;
