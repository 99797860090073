import { Theme } from "@mui/material";

type AnalyticsPanelProps = {
  analyticsData: string;
  theme: Theme;
};

const AnalyticsPanel: React.FC<AnalyticsPanelProps> = ({
  analyticsData,
  theme,
}) => {
  return (
    <div>
      <div className="centered">Analytics</div>
      <textarea className="analytics-field" value={analyticsData} readOnly />
    </div>
  );
};

export default AnalyticsPanel;
