import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  TextField,
  Button,
  Theme,
} from "@mui/material";
import { useState, useRef, useEffect } from "react";
import axios from "axios";

type HintDialogProps = {
  isOpen: boolean;
  closeModal: () => void;
  theme: Theme;
};

const HintDialog: React.FC<HintDialogProps> = ({
  isOpen,
  closeModal,
  theme,
}) => {
  const [oneWord, setOneWord] = useState<string>("");
  const [basicForm, setBasicForm] = useState<string>("");
  const [hintContent, setHintContent] = useState<string>("");

  const inputRef = useRef<HTMLInputElement>(null);

  const handleWordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setOneWord(e.target.value);
  };

  const getBasicForm = async () => {
    setBasicForm("");

    try {
      const res = await axios.post(`/basic-forms`, {
        word: oneWord,
      });
      //console.log(res.data.response);
      setBasicForm(res.data.response.basicForm);
    } catch (error) {
      console.error("Error fetching response:", error);
    }
  };

  const getHint = async () => {
    setHintContent("");
    try {
      const res = await axios.post(`/hint`, {
        word: oneWord,
      });
      setHintContent(res.data.response.explanation);
    } catch (error) {
      console.error("Error fetching response:", error);
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      if (isOpen && inputRef.current) {
        //console.log("useEffect");
        inputRef.current.focus();
      }
    }, 100); // Adjust the delay here

    return () => clearTimeout(timer);
  }, [isOpen]);

  return (
    <Dialog
      open={Boolean(isOpen)}
      onClose={closeModal}
      maxWidth="lg"
      PaperProps={{
        style: {
          //maxHeight: "75%", // You can use any unit or percentage you prefer
          width: "50%",
          //overflowY: "auto", // Adds scrollbar when content overflows
        },
      }}
    >
      <DialogTitle>{"Word Hint"}</DialogTitle>
      <DialogContent>
        {/* Additional information about the student can go here */}
        <Grid
          container
          direction="column"
          alignItems="center"
          spacing={0}
          sx={{ marginBottom: "45px" }}
        >
          {/*<Grid item sx={{ marginLeft: "0%" }}>
             <p className="">Check a word</p>
          </Grid> */}

          {/* Word input field */}
          <Grid
            item
            sx={{
              marginLeft: "0%",
              alignItems: "center",
              margiBottom: "20%",
            }}
          >
            <TextField
              inputRef={inputRef}
              multiline
              variant="standard"
              style={{
                minHeight: "30px",
                backgroundColor: theme.palette.background.default,
                borderRadius: 30,
                padding: "10px 15px 10px 15px",
                minWidth: "200px",
                //width: "100%",
                marginBottom: "5vh",
                //marginLeft: "3vw",
              }}
              value={oneWord}
              onChange={handleWordChange}
              InputProps={{
                readOnly: false,
                style: {
                  minHeight: "30px", // Increased height
                  fontSize: "18px",
                  alignItems: "flex-start",
                },
                disableUnderline: true,
              }}
              fullWidth
            />
          </Grid>

          {/* Next row - basic form */}
          <Grid
            container
            alignItems="left"
            spacing={3}
            sx={{ marginBottom: "15px" }}
          >
            <Grid item xs={3}>
              <Button
                variant="contained"
                color="secondary"
                onClick={getBasicForm}
                sx={{
                  width: 140,
                  height: 45,
                }}
              >
                Basic form
              </Button>
            </Grid>
            <Grid item xs={9}>
              <TextField
                variant="standard"
                style={{
                  backgroundColor: theme.palette.primary.main,
                  borderRadius: 20,
                  width: "90%",
                  height: "50px",
                  margin: "0 0 0 15px",
                  //boxSizing: "border-box",
                }}
                value={basicForm}
                InputProps={{
                  readOnly: true,
                  style: {
                    //height: "120px", // Increased height
                    //width: "120px",
                    textAlign: "center",
                    alignItems: "center", // Align items center for vertical centering
                    justifyContent: "center",
                    fontSize: "18px",
                    padding: "8px 15px 8px 15px",
                  },
                  disableUnderline: true,
                }}
              />
            </Grid>
          </Grid>
          <Grid
            container
            alignItems="center"
            spacing={2}
            sx={{ marginBottom: "15px" }}
          >
            <Grid item xs={3}>
              <Button
                variant="contained"
                color="secondary"
                onClick={getHint}
                sx={{
                  width: 140,
                  height: 45,
                }}
                disabled={true}
              >
                Explanation
              </Button>
            </Grid>
            <Grid item xs={9}>
              <TextField
                variant="standard"
                style={{
                  backgroundColor: theme.palette.primary.main,
                  borderRadius: 20,
                  width: "90%",
                  height: "50px",
                  margin: "0 0 0 15px",
                  //boxSizing: "border-box",
                }}
                //className="sanakirja-field"
                value={hintContent}
                InputProps={{
                  readOnly: true,
                  style: {
                    //height: "120px", // Increased height
                    //width: "120px",
                    textAlign: "center",
                    alignItems: "center", // Align items center for vertical centering
                    justifyContent: "center",
                    fontSize: "18px",
                    padding: "8px 15px 8px 15px",
                  },
                  disableUnderline: true,
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <button onClick={closeModal}>Close</button>
      </DialogActions>
    </Dialog>
  );
};

export default HintDialog;
