import TextField from "@mui/material/TextField";

import { Theme } from "@mui/material";

type GradeOutputProps = {
  grade: string;
  theme: Theme;
};

const GradeOutput: React.FC<GradeOutputProps> = ({ grade, theme }) => {
  const renderStyledText = () => {
    // Split the value into different parts
    const parts = grade.split("/").map((part) => Number(part));

    // Apply different styles to different parts
    return (
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          pointerEvents: "none",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
        }}
      >
        <span
          style={{
            fontSize: "60px",
            color: parts[0] < 4 ? "red" : parts[0] < 7 ? "#cd860b" : "green",
            position: "relative",
            top: "-10px",
            fontWeight: "bold",
          }}
        >
          {/* Print only when the received grade is not NULL de9618*/}
          {parts[0] ? parts[0] + "" : ""}
        </span>
        {parts[1] && (
          <span
            style={{
              fontSize: "60px",
              marginLeft: "5px",
              position: "relative",
              top: "-4px",
              color: "#4F7989",
            }}
          >
            /
          </span>
        )}
        {parts[1] && (
          <span
            style={{
              fontSize: "30px",
              marginLeft: "0px",
              position: "relative",
              top: "15px",
              color: "#4F7989",
            }}
          >
            {parts[1]}
          </span>
        )}
      </div>
    );
  };

  return (
    <div style={{ position: "relative", width: "140px", height: "140px" }}>
      <TextField
        className="center-text-field"
        variant="standard"
        style={{
          backgroundColor: theme.palette.background.paper,
          borderRadius: 20,
          width: "100%", // Fill the parent div
          height: "100%", // Fill the parent div
          boxSizing: "border-box",
        }}
        value={grade}
        InputProps={{
          readOnly: true,
          style: {
            textAlign: "center",
            alignItems: "center", // Align items center for vertical centering
            justifyContent: "center",
            fontSize: "40px", // This will be invisible, but kept for layout
            color: "transparent", // Make the text transparent
          },
          disableUnderline: true,
        }}
      />
      {renderStyledText()}
    </div>
  );
};

export default GradeOutput;
