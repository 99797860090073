import { useState, useEffect } from "react";
import { Container, Box, useTheme } from "@mui/material";
import "./App.css";
import MyAppBar from "./components/MyAppBar";
import LandingPage from "./pages/LandingPage";
import MainService from "./pages/MainService";

import { createClient, SupabaseClient, Session } from "@supabase/supabase-js";
//import { Auth } from "@supabase/auth-ui-react";
//import { ThemeSupa } from "@supabase/auth-ui-shared";

const supabase_url: string = process.env.REACT_APP_SUPABASE_URL || "";
const supabase_token: string | undefined =
  process.env.REACT_APP_SUPABASE_TOKEN || "";

//console.log(supabase_url);

const supabase: SupabaseClient = createClient(supabase_url, supabase_token);

const App: React.FC = () => {
  const theme = useTheme();

  const [session, setSession] = useState<Session | null>(null);

  useEffect(() => {
    supabase.auth.getSession().then(({ data: { session } }) => {
      setSession(session);
    });
    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange((_event, session) => {
      setSession(session);
    });
    return () => subscription.unsubscribe();
  }, []);

  return (
    <Container maxWidth={false}>
      <MyAppBar supabase={supabase} session={session} setSession={setSession} />
      {/* Using Box here to manage background color dynamically from theme   */}
      <Box
        sx={(theme) => ({
          height: "100vh",
          background: `linear-gradient(to bottom, ${theme.palette.primary.main}, #FFFFFF)`,
        })}
      >
        {session ? <MainService /> : <LandingPage />}
      </Box>
    </Container>
  );
};

export default App;
