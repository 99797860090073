import { TextField, Theme } from "@mui/material";

type EvaluationOutputProps = {
  evaluationContent: string;
  theme: Theme;
};

const EvaluationOutput: React.FC<EvaluationOutputProps> = ({
  evaluationContent,
  theme,
}) => {
  return (
    <TextField
      multiline
      variant="standard"
      style={{
        backgroundColor: theme.palette.background.paper,
        borderRadius: 20,
        padding: "10px 15px 10px 15px",
        width: "100%",
        minHeight: "20vh",
        boxSizing: "border-box",
      }}
      value={evaluationContent}
      InputProps={{
        readOnly: true,
        style: {
          minHeight: "140px", // Increased height
          fontSize: "14px",
          alignItems: "flex-start",
        },
        disableUnderline: true,
      }}
      fullWidth
    />
  );
};

export default EvaluationOutput;
