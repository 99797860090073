import TextField from "@mui/material/TextField";
import { Theme } from "@mui/material";

type UserInputProps = {
  handleInputChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  userContent: string;
  theme: Theme;
};

const UserInput: React.FC<UserInputProps> = ({
  handleInputChange,
  userContent,
  theme,
}) => {
  return (
    <TextField
      multiline
      variant="standard"
      style={{
        minHeight: "15vh",
        backgroundColor: theme.palette.background.default,
        borderRadius: 30,
        padding: "10px 15px 10px 15px",
        width: "100%",
        //marginLeft: "3vw",
      }}
      value={userContent}
      onChange={handleInputChange}
      InputProps={{
        readOnly: false,
        style: {
          minHeight: "110px", // Increased height
          fontSize: "14px",
          alignItems: "flex-start",
        },
        disableUnderline: true,
      }}
      fullWidth
    />
  );
};

export default UserInput;
