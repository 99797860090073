import { createTheme } from "@mui/material/styles";

const Theme = createTheme({
  palette: {
    // main background color (used in gradient)
    primary: {
      main: "#9EC8D4",
    },
    // button color
    secondary: {
      main: "#4F7989",
    },
    error: {
      main: "#E57373", // Example color for error states
    },
    text: {
      primary: "#333333", //
      secondary: "#555555", //
    },
    background: {
      default: "#f4f4f4", // almost white color
      paper: "#bfdbe3", // used for Task TextField background
    },
    action: {
      active: "#001E3C", // Example active state color
      hover: "#005B96", // Hover state color
    },
  },
  components: {
    MuiContainer: {
      styleOverrides: {
        root: {
          paddingLeft: "0px !important",
          paddingRight: "0px !important",
        },
      },
    },
  },
  // ...other theme customizations
});

export default Theme;
