import React, { useEffect } from "react";
import { Auth } from "@supabase/auth-ui-react";
import { SupabaseClient, Session } from "@supabase/supabase-js";
import { ThemeSupa } from "@supabase/auth-ui-shared";

interface AuthModalProps {
  session: Session | null;
  supabaseClient: SupabaseClient;
  showModal: boolean;
  onClose: () => void;
}

const AuthModal: React.FC<AuthModalProps> = ({
  session,
  supabaseClient,
  showModal,
  onClose,
}) => {
  useEffect(() => {
    if (session) {
      onClose(); // Close the modal if there's a session
    }
  }, [supabaseClient, onClose]);

  if (!showModal) {
    return null;
  }

  return (
    <div>
      <div className="modal">
        <div className="modal-content" style={{ width: "400px" }}>
          {/* Add your modal content here */}
          <h2>Modal Title</h2>
          <button onClick={onClose}>Close</button>
          <Auth
            supabaseClient={supabaseClient}
            appearance={{ theme: ThemeSupa }}
            providers={["google"]}
          />
        </div>
      </div>
    </div>
  );
};

export default AuthModal;
